import Image1 from "../images/photos/axolotl1.jpg"
import Image2 from "../images/photos/axolotl2.jpg"
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageCarousel = () => {
    const images = [
        { src: Image1, alt: 'Axolotl 1' },
        { src: Image2, alt: 'Axolotl 2' }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'linear',
        pauseOnHover: true,
        arrows: true
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="slide-container">
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="carousel-image"
                        />
                    </div>
                ))}
            </Slider>
            <style jsx global>{`
                .carousel-container {
                    position: relative;
                    width: 100%;
                    max-width: 4xl;
                    margin: 0 auto;
                    height: 24rem;
                    overflow: hidden;
                    border-radius: 0.5rem;
                }

                .slide-container {
                    position: relative;
                    height: 24rem;
                }

                .carousel-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                /* Slick slider custom styles */
                .slick-slider {
                    height: 100%;
                }

                .slick-list, .slick-track {
                    height: 100%;
                }

                .slick-slide > div {
                    height: 100%;
                }

                .slick-prev, .slick-next {
                    z-index: 10;
                }

                .slick-prev {
                    left: 25px;
                }

                .slick-next {
                    right: 25px;
                }

                .slick-dots {
                    bottom: 25px;
                }

                .slick-dots li button:before {
                    color: white;
                    font-size: 10px;
                    opacity: 0.7;
                }

                .slick-dots li.slick-active button:before {
                    opacity: 1;
                    color: white;
                }
            `}</style>
        </div>
    );
};

export default ImageCarousel;
import React from 'react';
// Tech Icons
import ReactIcon from "../images/icons/react.svg"
import JavaScriptIcon from "../images/icons/javascript.svg"
import NodeIcon from "../images/icons/nodejs.svg"
import PythonIcon from "../images/icons/python.svg"
import CSSIcon from "../images/icons/css.svg"
import HTMLIcon from "../images/icons/html.svg"
import GitIcon from "../images/icons/git.svg"
import DockerIcon from "../images/icons/docker.svg"
import AWSIcon from "../images/icons/aws.svg"
import TypeScriptIcon from "../images/icons/typescript.svg"
import JavaIcon from "../images/icons/java.svg"
import StripeIcon from "../images/icons/stripe.svg"
import SwiftIcon from "../images/icons/swift.svg"
import FlaskIcon from "../images/icons/flask.svg"
import SpringBootIcon from "../images/icons/springboot.svg"

const TechStack = () => {
    const technologies = [
        {
            name: 'React',
            icon: <img src={ReactIcon} width="64" height="64" alt="React logo"/>
        },
        {
            name: 'JavaScript',
            icon: <img src={JavaScriptIcon} width="64" height="64" alt="JavaScript logo"/>
        },
        {
            name: 'Node.js',
            icon: <img src={NodeIcon} width="64" height="64" alt="Node.js logo"/>
        },
        {
            name: 'Python',
            icon: <img src={PythonIcon} width="64" height="64" alt="Python logo"/>
        },
        {
            name: 'CSS3',
            icon: <img src={CSSIcon} width="64" height="64" alt="CSS3 logo"/>
        },
        {
            name: 'HTML5',
            icon: <img src={HTMLIcon} width="64" height="64" alt="HTML5 logo"/>
        },
        {
            name: 'Git',
            icon: <img src={GitIcon} width="64" height="64" alt="Git logo"/>
        },
        {
            name: 'Docker',
            icon: <img src={DockerIcon} width="64" height="64" alt="Docker logo"/>
        },
        {
            name: 'AWS',
            icon: <img src={AWSIcon} width="64" height="64" alt="AWS logo"/>
        },
        {
            name: 'TypeScript',
            icon: <img src={TypeScriptIcon} width="64" height="64" alt="TypeScript logo"/>
        },
        {
            name: 'Java',
            icon: <img src={JavaIcon} width="64" height="64" alt="Java logo"/>
        },
        {
            name: 'Stripe',
            icon: <img src={StripeIcon} width="64" height="64" alt="Stripe logo"/>
        },
        {
            name: 'Swift',
            icon: <img src={SwiftIcon} width="64" height="64" alt="Swift logo"/>
        },
        {
            name: 'Flask',
            icon: <img src={FlaskIcon} width="64" height="64" alt="Swift logo"/>
        },
        {
            name: 'SpringBoot',
            icon: <img src={SpringBootIcon} width="64" height="64" alt="Swift logo"/>
        }
    ];

    return (
        <div className="relative overflow-hidden">
            <div className="flex relative">
                <div
                    className="flex items-center"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        whiteSpace: "nowrap",
                        transform: "translateX(-75%)",
                        animation: "scroll 50s linear infinite"
                    }}
                >
                    {technologies.map((tech, index) => (
                        <span
                            key={`first-${index}`}
                            className="mx-3"
                            style={{
                                display: "inline-block",
                                flexShrink: 0
                            }}
                        >
                        {tech.icon}
                    </span>
                    ))}
                    {technologies.map((tech, index) => (
                        <span
                            key={`second-${index}`}
                            className="mx-3"
                            style={{
                                display: "inline-block",
                                flexShrink: 0
                            }}
                        >
                        {tech.icon}
                    </span>
                    ))}
                    {technologies.map((tech, index) => (
                        <span
                            key={`third-${index}`}
                            className="mx-3"
                            style={{
                                display: "inline-block",
                                flexShrink: 0
                            }}
                        >
                        {tech.icon}
                    </span>
                    ))}
                    {technologies.map((tech, index) => (
                        <span
                            key={`fourth-${index}`}
                            className="mx-3"
                            style={{
                                display: "inline-block",
                                flexShrink: 0
                            }}
                        >
                        {tech.icon}
                    </span>
                    ))}
                </div>
            </div>

            <style>
                {`
                @keyframes scroll {
                    from {
                        transform: translateX(-75%);
                    }
                    to {
                        transform: translateX(-175%);
                    }
                }

                /* Ensure no horizontal scroll */
                html, body {
                    overflow-x: hidden;
                }
            `}
            </style>
        </div>
    );
}

export default TechStack;
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";

const App = () => {
    return (
        <BrowserRouter>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <main className="flex-grow pt-16">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/coding" element={<div className="max-w-6xl mx-auto px-4 py-8">Coding Projects</div>} />
                        <Route path="/music" element={<div className="max-w-6xl mx-auto px-4 py-8">Music Projects</div>} />
                        <Route path="/crafts" element={<div className="max-w-6xl mx-auto px-4 py-8">Crafts Projects</div>} />
                        <Route path="/resume" element={<div className="max-w-6xl mx-auto px-4 py-8">Resume</div>} />
                        <Route path="/blog" element={<div className="max-w-6xl mx-auto px-4 py-8">Blog</div>} />
                    </Routes>
                </main>
            </div>
        </BrowserRouter>
    );
};

export default App;
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Code, Music, Brush, FileText, BookOpen, Menu, X, Home } from 'lucide-react';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    useEffect(() => {
        const handleResize = () => {
            const mobile = window.innerWidth < 1024;
            setIsMobile(mobile);
            if (!mobile) setIsOpen(false);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    const navItems = [
        { icon: <Home size={24} />, text: 'Home', path: '/' },
        { icon: <Code size={24} />, text: 'Coding', path: '/coding' },
        { icon: <Music size={24} />, text: 'Music', path: '/music' },
        { icon: <Brush size={24} />, text: 'Crafts', path: '/crafts' },
        { icon: <FileText size={24} />, text: 'Resume', path: '/resume' },
        { icon: <BookOpen size={24} />, text: 'Blog', path: '/blog' },
    ];

    const NavLink = ({ item, mobile = false }) => (
        <Link
            to={item.path}
            onClick={() => setIsOpen(false)}
            className={`
                ${mobile
                ? `flex items-center gap-4 p-4 rounded-xl backdrop-blur-sm transition-all duration-200
                        ${location.pathname === item.path
                    ? 'bg-blue-500/10 text-blue-400 border border-blue-500/20'
                    : 'bg-zinc-800/40 text-zinc-400 border border-zinc-700/30 hover:bg-zinc-700/40 hover:text-zinc-100'
                }`
                : ''
            }
                ${!mobile ? (location.pathname === item.path ? 'active' : '') : ''}
            `}
        >
            <span className={`${mobile ? 'text-2xl' : 'text-xl'} group-hover:scale-110 transition-transform duration-300`}>
                {item.icon}
            </span>
            <span className={`font-medium ${mobile ? 'text-lg' : 'text-sm'} whitespace-nowrap`}>
                {item.text}
            </span>
        </Link>
    );

    return (
        <nav className="fixed top-0 left-0 w-full bg-zinc-900/80 backdrop-blur-md border-b border-zinc-800 z-50">
            {isMobile ? (
                <>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="mobile-toggle"
                    >
                        <span>{isOpen ? <X size={24} /> : <Menu size={24} />}</span>
                        <span className="text-sm font-medium">Menu</span>
                    </button>

                    <div
                        className={`mobile-overlay ${isOpen ? 'active' : ''}`}
                        onClick={() => setIsOpen(false)}
                    />

                    <div className={`mobile-menu ${isOpen ? 'active' : ''}`}>
                        <div className="mobile-menu-content">
                            {navItems.map((item, index) => (
                                <NavLink
                                    key={index}
                                    item={item}
                                    mobile={true}
                                />
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-center h-16">
                        <div className="desktop-nav-container">
                            {navItems.map((item, index) => (
                                <NavLink key={index} item={item} mobile={false} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
import TechStack from "../components/TechStack";
import ImageCarousel from "../components/ImageCarousel";

const Home = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <div style={{ maxWidth: '1024px', margin: '0 auto', padding: '0 24px' }}>
                <ImageCarousel />

                <div style={{ maxWidth: '600px', margin: '0 auto' }} className="py-16">
                    <h1 className="text-4xl sm:text-5xl font-bold text-center text-gray-900">
                        Hi, I'm <span className="text-indigo-600">Joshua</span> !
                    </h1>
                    <p className="mt-6 text-lg text-gray-600 text-center">
                        I'm a passionate developer who loves creating beautiful and functional web applications.
                    </p>
                    <p className="mt-4 text-lg text-gray-600 text-center">
                        When I'm not coding, you can find me making music or working on various craft projects.
                        I believe in the power of creative expression through both technology and art.
                    </p>
                </div>

                <div style={{ maxWidth: '800px', margin: '0 auto' }} className="bg-blue-50 p-8 rounded-xl">
                    <TechStack />
                </div>
            </div>
        </div>
    );
};

export default Home;